var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoContentLoader',{attrs:{"loading":_vm.loading}},[_c('FormRulesProvider',{key:_vm.service.id,attrs:{"module-name":_vm.$constants.SERVICE_CATALOG,"parent-resource-id":_vm.service.id,"custom-fields":_vm.formFields}},[_c('MRow',{staticClass:"min-w-0",attrs:{"gutter":16}},[_c('MCol',{staticClass:"min-w-0",class:{
          'bg-neutral-lightest': _vm.giveExternalUpdateOptoin,
          'py-2 px-6': _vm.giveExternalUpdateOptoin,
          rounded: _vm.giveExternalUpdateOptoin,
        }},[_c('MRow',{staticClass:"min-w-0"},[(_vm.giveExternalUpdateOptoin)?_c('MCol',{staticClass:"flex min-w-0",staticStyle:{"justify-content":"flex-end"},attrs:{"size":12}},[_c('MButton',{staticClass:"mx-1",attrs:{"disabled":_vm.disabled || _vm.service.archived,"variant":"neutral-light","shadow":false,"shape":"circle"},on:{"click":_vm.handleEditCustomField}},[_c('MIcon',{attrs:{"name":"pencil"}})],1)],1):_vm._e(),_c('MCol',{attrs:{"auto-size":""}},[_c('img',{attrs:{"src":_vm.service.image,"height":"150","width":"150"}})]),_c('MCol',{staticClass:"flex-1"},[_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.service.name)+" "+_vm._s(_vm.service.archived ? ("(" + (_vm.$t('archived')) + ")") : '')+" ")]),(
                (!_vm.isPortalLogin && _vm.service.amount > 0) ||
                (_vm.isPortalLogin && _vm.service.showInCustomerPortalAmount)
              )?_c('div',{staticClass:"flex"},[_vm._v(" "+_vm._s(_vm.$tc('cost'))+" : "+_vm._s(_vm.service.amount)+" "),_c('CurrencyPicker',{attrs:{"text-only":"","disabled":""},model:{value:(_vm.service.currencyId),callback:function ($$v) {_vm.$set(_vm.service, "currencyId", $$v)},expression:"service.currencyId"}})],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.service.serviceDescription)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.serviceCatagoryName)+" ")])])],1),_c('MRow',{staticClass:"mt-4"},[_c('MCol',{attrs:{"size":8}},[(_vm.form.id && _vm.formFields.length)?_c('FormConsumer',{attrs:{"value":_vm.resource,"form-fields":_vm.formFields,"disabled":_vm.additionalInfoDisabled,"module-name":_vm.$constants.SERVICE_CATALOG,"apply-form-rules":"","avoid-default-value":true},on:{"submit":_vm.handleFormSubmitted},scopedSlots:_vm._u([{key:"submit",fn:function(){return [_c('MButton',{attrs:{"loading":_vm.processing,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")])]},proxy:true},{key:"reset",fn:function(){return [_c('span')]},proxy:true}],null,false,3486433547)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }