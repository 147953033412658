<template>
  <div class="flex flex-col flex-1">
    <div class="font-semibold py-1">{{ stage.text }}</div>
    <div>
      <!-- runing stage excluded close and approval -->
      <template
        v-if="running && stage.key !== 'close' && stage.key !== 'approval'"
      >
        <label v-if="!resource.prevStatusId" class="mb-2 text-neutral">
          {{ $tc('select_status') }}
        </label>
        <FlotoStatusPicker
          v-if="resource.prevStatusId"
          :value="statusId"
          :stage="stage.key"
          :module-name="moduleName"
          disabled
        />
        <FlotoStatusPicker
          v-else
          :disabled="disabled || singleFieldRulesStateFn('statusId').disable"
          :value="statusId"
          :stage="stage.key"
          :module-name="moduleName"
          as-input
          :allow-clear="false"
          @change="handleChange"
        />
      </template>
      <!-- for only current running approval stage -->
      <template v-if="running && stage.key === 'approval'">
        <FlotoStatusPicker
          disabled
          :value="statusId"
          :stage="stage.key"
          :module-name="moduleName"
        />
      </template>
      <div v-if="running && resource.backToPlanning && !disabled">
        <a @click="backToPlanning">
          <MIcon name="long-arrow-left" />
          {{ $t('back') }} {{ $t('to_lower') }} {{ $t('planning') }}
        </a>
      </div>
      <!-- all completed stag -->
      <FlotoStatusPicker
        v-if="completed"
        disabled
        :value="prevStatusId"
        :allow-clear="false"
        :stage="stage.key"
        :module-name="moduleName"
      />
    </div>
    <div
      v-if="
        resource[`${stage.key}EndTime`] &&
        (completed || (running && stage.key === 'close'))
      "
      class="py-1"
    >
      {{ resource[`${stage.key}EndTime`] | datetime }}
    </div>
  </div>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
export default {
  name: 'StageActionInfo',
  props: {
    stage: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    completed: { type: Boolean, default: false },
    pending: { type: Boolean, default: false },
    running: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    moduleName: { type: String, required: true },
    singleFieldRulesStateFn: {
      type: Function,
      default: (e) => e,
    },
  },
  computed: {
    ...StatusComputed,
    currurentStatus() {
      if (this.resource.statusId) {
        return this[`${this.moduleName}FindStatusFromId`](
          this.resource.statusId
        )
      }
      return undefined
    },
    prevStatusId() {
      if (this.resource.prevStatusId && this.running) {
        return this.resource.prevStatusId
      }
      const currentList = (this[`${this.moduleName}Status`] || [])
        .filter((o) => o.stage === this.stage.key)
        .map((s) => ({ ...s, text: s.name }))
      return (
        currentList[currentList.length - 1] &&
        currentList[currentList.length - 1].key
      )
    },
    statusId() {
      if (this.resource.prevStatusId) {
        return this.resource.prevStatusId
      }
      return this.resource.statusId
    },
  },
  methods: {
    handleChange(data) {
      this.$emit('change', { statusId: data })
    },
    backToPlanning() {
      this.handleChange(
        this[`${this.moduleName}FindStatus`]('in progress', 'planning').id
      )
    },
  },
}
</script>
