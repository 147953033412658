<template>
  <MRow :gutter="0" class="mt-2">
    <MCol
      v-if="hiddenFields.indexOf('statusId') === -1"
      id="status-picker"
      :size="3"
    >
      <FlotoStatusPicker
        :value="value.statusId"
        :module-name="moduleName"
        :disabled="
          disabled ||
          moduleName === $constants.CHANGE ||
          moduleName === $constants.RELEASE ||
          singleFieldRulesStateFn('statusId').disable
        "
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('statusId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('statusId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ statusId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('status')"
            icon-name="vector"
            custom-icon
            :required="isRequiredFn('statusId')"
            :disabled="
              disabled ||
              moduleName === $constants.CHANGE ||
              moduleName === $constants.RELEASE ||
              singleFieldRulesStateFn('statusId').disable
            "
          />
        </template>
      </FlotoStatusPicker>
    </MCol>
    <MCol
      v-if="hiddenFields.indexOf('priorityId') === -1"
      id="priority-picker"
      :size="3"
    >
      <FlotoPriorityPicker
        :disabled="disabled || singleFieldRulesStateFn('priorityId').disable"
        :placeholder="disabled ? '---' : undefined"
        :value="value.priorityId"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('priorityId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('priorityId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ priorityId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('priority')"
            icon-name="priority"
            :disabled="
              disabled || singleFieldRulesStateFn('priorityId').disable
            "
            :required="isRequiredFn('priorityId')"
          />
        </template>
      </FlotoPriorityPicker>
    </MCol>
    <MCol
      v-if="hiddenFields.indexOf('urgencyId') === -1"
      id="urgency-picker"
      :size="3"
    >
      <FlotoUrgencyPicker
        :value="value.urgencyId"
        :disabled="disabled || singleFieldRulesStateFn('urgencyId').disable"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('urgencyId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('urgencyId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ urgencyId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('urgency')"
            icon-name="business-time"
            :disabled="disabled || singleFieldRulesStateFn('urgencyId').disable"
            :required="isRequiredFn('urgencyId')"
          />
        </template>
      </FlotoUrgencyPicker>
    </MCol>
    <MCol
      v-if="hiddenFields.indexOf('impactId') === -1"
      id="impact-picker"
      :size="3"
    >
      <FlotoImpactPicker
        :placeholder="disabled ? '---' : undefined"
        :value="value.impactId"
        :disabled="disabled || singleFieldRulesStateFn('impactId').disable"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('impactId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('impactId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ impactId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('impact')"
            icon-name="impact"
            :disabled="disabled || singleFieldRulesStateFn('impactId').disable"
            :required="isRequiredFn('impactId')"
          />
        </template>
      </FlotoImpactPicker>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'PrimaryRow',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, required: true },
    moduleName: { type: String, required: true },
    hiddenFields: {
      type: Array,
      default() {
        return []
      },
    },
    isRequiredFn: {
      type: Function,
      default: (e) => e,
    },
    singleFieldRulesStateFn: {
      type: Function,
      default: (e) => e,
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>
