<template>
  <MRow>
    <MCol :size="6">
      <FlotoContentLoader :loading="loading">
        <template v-if="!loading">
          <WorkDetail
            v-model="work.rootCause"
            :disabled="disabled"
            prop-key="rootCause"
            :parent-id="resource.id"
            :module-name="$constants.PROBLEM"
          >
            <template v-slot:title>
              {{ $t('rootcause') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-model="work.symptomps"
            :disabled="disabled"
            prop-key="symptomps"
            :parent-id="resource.id"
            :module-name="$constants.PROBLEM"
          >
            <template v-slot:title>
              {{ $t('symptomps') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-model="work.impact"
            :disabled="disabled"
            prop-key="impact"
            :parent-id="resource.id"
            :module-name="$constants.PROBLEM"
          >
            <template v-slot:title>
              {{ $t('impact') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-model="work.workaround"
            :disabled="disabled"
            prop-key="workaround"
            :parent-id="resource.id"
            :module-name="$constants.PROBLEM"
          >
            <template v-slot:title>
              {{ $t('workaround') }}
            </template>
          </WorkDetail>
        </template>
      </FlotoContentLoader>
    </MCol>
    <MCol :size="6">
      <ConversationContainer
        :resource-id="resource.id"
        :module-name="$constants.PROBLEM"
        allow-knowledges
        :rich-box-rows="8"
        :disabled="disabled"
        allow-save-in-draft
        :hide-search-bar="hideSearchBar"
        @work-received="handleWorkReceived"
        @solution-added="$emit('solution-added', $event)"
      />
    </MCol>
  </MRow>
</template>

<script>
import Omit from 'lodash/omit'
import ConversationContainer from '@components/conversation/conversation-container.vue'
import WorkDetail from './work-detail'

export default {
  name: 'ProblemAnalysisTab',
  components: { ConversationContainer, WorkDetail },
  props: {
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    hideSearchBar: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      work: {
        rootCause: {},
        symptomps: {},
        impact: {},
        workaround: {},
      },
    }
  },
  methods: {
    handleWorkReceived(data) {
      this.work = {
        ...Omit(data, ['solution', 'diagnosis']),
      }
      this.loading = false
    },
  },
}
</script>
